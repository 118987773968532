import "./login.css";
import { useState } from "react";
import jwt_decode from "jwt-decode";
import AllFetchMethod from "../__Functions/Fetch/AllFetchMethod";
import LoadingMask from "../LoadingMask/LoadingMask";

export default function Login({ setValidUser }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [apiMessage, setApiMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const userLogin = async () => {

    setLoading(true);
    const result = await AllFetchMethod("POST", "/api/login", {
      username,
      password,
    });
    if (result.token) {
      let infoFromToken = jwt_decode(result.token);
      localStorage.setItem("token", result.token);
      localStorage.setItem("token_exp", infoFromToken.exp);
      setApiMessage("");
      setValidUser(result.user_info);
      window.location.href = `${process.env.REACT_APP_SUBFOLDER}/statisztikak`;
    } else {
      setApiMessage("Sikertelen belépés!");
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <div className="loginBlock">
      <div className="username">
        <div>Felhasználónév: </div>
        <input
          type="text"
          value={username}
          placeholder="Felhasználónév..."
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
      </div>
      <div className="passW">
        <div>Jelszó: </div>
        <input
          type="password"
          value={password}
          placeholder="..."
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>
      <div className="sendBtnDiv">
        <button className="saveBtn" onClick={() => userLogin()}>
          Bejelentkezés
        </button>
      </div>
      {loading ? (
        <LoadingMask />
      ) : (
        <div>
          {apiMessage !== "" && <div className="error">{apiMessage}</div>}
        </div>
      )}
    </div>
  );
}
