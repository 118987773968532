import { useState, useEffect, useRef } from "react";

import axios from "axios";
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

export default function ImageUploadNoDB({ getUploadedImages }) {
  const ref = useRef();
  const reset = () => {
    ref.current.value = "";
  };

  const [name, setName] = useState("");
  const [file, setFile] = useState("");

  const [stillUploading, setStillUploading] = useState(false);

  useEffect(() => {}, [name, file]);

  const updateConfig = () => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  };

  const uploadImage = async () => {
    if (!stillUploading) {
      updateConfig();
      setStillUploading(true);
      const formData = new FormData();
      formData.append("image", file);

      axios
        .post(
          `${process.env.REACT_APP_HTTP_HOST}/api/auth/editor/editor-images${process.env.REACT_APP_PHP}`,
          formData,
          config
        )
        .then((res) => {
          console.log(res.data);
          getUploadedImages();
        })
        .catch((err) =>
          err.response
            ? console.log(err.response.status, err.response.data)
            : console.error(err)
        )
        .finally(() => {
          setName("");
          setFile("");
          reset();
          setStillUploading(false);
        });
    }
  };

  const onChangeImg = (event) => {
    setName(URL.createObjectURL(event.target.files[0]));
    setFile(event.target.files[0]);
  };

  return (
    <div className="uploadBlock">
      {name !== "" && (
        <div className="uploadPreview small">
          <img src={name} alt="File preview" />
        </div>
      )}

      <form className="uploadForm">
        <input
          type="file"
          name="image"
          ref={ref}
          onChange={(event) => onChangeImg(event)}
        />
        <button type="button" onClick={() => uploadImage()}>
          Feltöltés
        </button>
      </form>
    </div>
  );
}
