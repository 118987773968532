import "./logOutCounter.css";
import React, { useEffect } from "react";
import { useState } from "react";

export default function LogoutCounter({ zzz = 1 }) {
  const [styleClass, setStyleClass] = useState("deff");
  const [showSec, setShowSec] = useState(false);
  const [started, setStarted] = useState(false);
  const [actualInterval, setActualInterval] = useState(0);
  const [minuteToLogout, setMinuteToLogout] = useState({
    allSec: "--",
    hh: "--",
    min: "--",
    sec: "--",
  });
  const timeStamp = Number(localStorage.getItem("token_exp"));
  const checkPopUp = () => {
    const allSec = parseInt(timeStamp - Date.now() / 1000);
    let hh = ("0" + parseInt(allSec / 60 / 60)).substring(-2);
    let min = ("0" + parseInt((allSec - hh * 3600) / 60)).slice(-2);
    let sec = ("0" + parseInt(allSec - hh * 3600 - min * 60)).slice(-2);

    setMinuteToLogout({ allSec, hh, min, sec });
    if (allSec >= 1200) {
      setStyleClass("deff");
    } else if (allSec >= 300) {
      setShowSec(true);
      setStyleClass("attention");
    } else {
      setStyleClass("attention extra");
    }
  };

  /*   const stopIt = () => {
    clearInterval(actualInterval);
    document.getElementById("allSecDiv").classList.remove("error");
  }; */
  const startCounter = () => {
    let actualCounter = setInterval(checkPopUp, 1000);
    setActualInterval(actualCounter);
  };

  useEffect(() => {
    if (!started) {
      setStarted(true);
      startCounter();
    }
  }, []);

  return (
    <div>
      {minuteToLogout.allSec > 0 && (
        <div className={`logCounter ${styleClass}`}>
          {minuteToLogout.hh}:{minuteToLogout.min}
          {showSec ? `:${minuteToLogout.sec}` : ":30"}
        </div>
      )}
    </div>
  );
}
