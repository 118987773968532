import axios from "axios";

// kell egy url
// meg egy ID
export default async function AllFetchMethod(typeX, url, payload = {}, logThis = false) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  let type = typeX.toLowerCase();
  let cucc;

  switch (type) {
    case "get":
      cucc = await axios
        .get(
          `${process.env.REACT_APP_HTTP_HOST}${url}${process.env.REACT_APP_PHP}`,
          config
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          err.response
            ? console.log(err.response.status, err.response.data)
            : console.error(err);
          return false;
        })
        .finally();
      break;
    case "get-query":
      let queries = "";
      const queryArray = [];
      for (const [key, value] of Object.entries(payload)) {
        queryArray.push(`${key}=${value}`);
      }
      if (queryArray.length > 0) {
        queries += "?";
        for (const item of queryArray) {
          queries += item;
        }
      }
      console.log(`query: ${queries}`);
      cucc = await axios
        .get(
          `${process.env.REACT_APP_HTTP_HOST}${url}${process.env.REACT_APP_PHP}${queries}`,
          config
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          err.response
            ? console.log(err.response.status, err.response.data)
            : console.error(err);
          return false;
        })
        .finally();
      break;
    case "post":
      cucc = await axios
        .post(
          `${process.env.REACT_APP_HTTP_HOST}${url}${process.env.REACT_APP_PHP}`,
          payload,
          config
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          err.response
            ? console.log(err.response.status, err.response.data)
            : console.error(err);
          return false;
        })
        .finally();
      break;
    case "put":
      cucc = await axios
        .put(
          `${process.env.REACT_APP_HTTP_HOST}${url}${process.env.REACT_APP_PHP}`,
          payload,
          config
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          err.response
            ? console.log(err.response.status, err.response.data)
            : console.error(err);
          return false;
        })
        .finally();
      break;
    case "patch":
      cucc = await axios
        .patch(
          `${process.env.REACT_APP_HTTP_HOST}${url}${process.env.REACT_APP_PHP}`,
          payload,
          config
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          err.response
            ? console.log(err.response.status, err.response.data)
            : console.error(err);
          return false;
        })
        .finally();
      break;
    case "delete":
      cucc = await axios
        .delete(
          `${process.env.REACT_APP_HTTP_HOST}${url}${process.env.REACT_APP_PHP}?del-id=${payload.id}`,
          config
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          err.response
            ? console.log(err.response.status, err.response.data)
            : console.error(err);
          return false;
        })
        .finally();
      break;

    default:
      console.log("valami hiba van");
      cucc = "fetch error!";
      break;
  }

  if (logThis) {
    console.log("### ", type, " --> ", url, payload, "result", cucc);
  }

  return cucc;
}
