import "./article.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import LoadingMask from "../LoadingMask/LoadingMask";
import ArticleValues from "./ArticleValues";

import axios from "axios";
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

export default function Article({ user, logTheUserOut }) {

  const [articleInfo, setArticleInfo] = useState("");
  const [article, setArticle] = useState(false);
  const [found, setFound] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (articleInfo === "uj") {
      // kell egy új product
      setArticle({});
      setLoading(false);
      setFound(true);
    } else if (articleInfo !== "uj") {
      // le kell kérni
      // persze itt még lehetne nézni, hogy szám-e..
      getArticle();
    }
  }, [articleInfo]);

  useEffect(() => {}, []);

  // useEffect(() => {}, [available]);

  useEffect(() => {}, [article]);

  let params = useParams();
  if (params.id !== articleInfo) {
    setArticleInfo(params.id);
  }

  const updateConfig = () => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  };

  const getArticle = () => {
    updateConfig();
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_HTTP_HOST}/api/auth/article/article${process.env.REACT_APP_PHP}?get-id=${articleInfo}`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.user) {
          logTheUserOut();
        }
        if (res.data.article) {
          setArticle(res.data.article);
          setFound(true);
        }
        console.log(res.data.article);
      })
      .catch((err) =>
        err.response
          ? console.log(err.response.status, err.response.data)
          : console.error(err)
      )
      .finally(setLoading(false));
  };

  return (
    <div className="article">
      <h1>{article && article.id ? article.title : "Új cikk hozzáadása"}</h1>

      {loading ? (
        <LoadingMask />
      ) : (
        <div>
          {found ? (
            <div>
              <ArticleValues article={article} getArticle={getArticle} />
            </div>
          ) : (
            <div className="error">A keresett termék nem található.</div>
          )}
        </div>
      )}
    </div>
  );
}
