import "./App.css";
import { useState, useEffect, useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AllFetchMethod from "./components/__Functions/Fetch/AllFetchMethod";

import MainAdmin from "./components/MainAdmin";
import Nav from "./components/Nav/Nav";
import Login from "./components/Login/Login";
import LogoutCounter from "./components/__HKA/AdminElements/LogoutCounter";

export default function App() {
  //  console.log("aaaappp");

  const [validUser, setValidUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userValidator();
  }, []);

  const userValidator = async () => {
    setValidUser(true);
    /* const result = await AllFetchMethod("POST", "/api/auth/validator", {}, true);
    if (result.success) {
      setValidUser(result.user);
    } else {
      // ez ide kell !!
      // ez ide kell !!
      logTheUserOut();
      // ez ide kell !!
    } */
    setLoading(false);
  };

  const logTheUserOut = () => {
    localStorage.removeItem("token");
    localStorage.setItem("token_exp", 0);
    setValidUser(false);
  };

  // addig töltődjön, amíg nincs erdemény

  return (
    <div className="App">
      {!validUser && !loading ? (
        <Login setValidUser={setValidUser} />
      ) : (
        <BrowserRouter basename="/xadmin/">
          <div className="leftDiv">
            <Nav />
          </div>
          <div className="centDiv">
            <div className="logoutRow">
              <a target="_blank" href={process.env.REACT_APP_HTTP_HOST}>
                Weboldal
              </a>
              <LogoutCounter />
              <button className="logoutBtn" onClick={() => logTheUserOut()}>
                Kijelentkezés
              </button>
            </div>
            <div className="mainContent">
              <Routes>
                <Route
                  path="/*"
                  element={
                    <MainAdmin user={validUser} logTheUserOut={logTheUserOut} />
                  }
                />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      )}
    </div>
  );
}
