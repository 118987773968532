import { useState, useEffect, useRef } from "react";

import axios from "axios";
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

export default function Upload({ item, apiString, refreshItem }) {
  const ref = useRef();
  const reset = () => {
    ref.current.value = "";
  };

  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const [waterMark, setWaterMark] = useState("center-center");
  const [opacity, setOpacity] = useState("60");

  const [stillUploading, setStillUploading] = useState(false);

  useEffect(() => {
    let storedWaterMark = localStorage.getItem("watermark");
    if (storedWaterMark) {
      setWaterMark(storedWaterMark);
    }
    let opacityWatermark = localStorage.getItem("opacityWatermark");
    if (opacityWatermark) {
      setOpacity(opacityWatermark);
    }
  });

  useEffect(() => {}, [name, file]);

  const updateConfig = () => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  };

  const uploadImage = async () => {
    if (!stillUploading) {
      updateConfig();
      setStillUploading(true);
      const formData = new FormData();
      formData.append("image", file);
      formData.append("watermark", waterMark);
      formData.append("opacity", opacity);
      formData.append("id", item.id);
      formData.append("alias", item.alias);
      axios
        .post(
          `${process.env.REACT_APP_HTTP_HOST}${apiString}${process.env.REACT_APP_PHP}`,
          formData,
          config
        )
        .then((res) => {
          console.log(res.data);
          refreshItem(item.id);
        })
        .catch((err) =>
          err.response
            ? console.log(err.response.status, err.response.data)
            : console.error(err)
        )
        .finally(() => {
          setName("");
          setFile("");
          reset();
          setStillUploading(false);
        });
    }
  };

  const onChangeImg = (event) => {
    setName(URL.createObjectURL(event.target.files[0]));
    setFile(event.target.files[0]);
  };

  const startUploadAfterSave = async () => {
    console.log("kellene képfeltöltés");

    uploadImage();
  };

  return (
    <div className="uploadBlock">
      {name !== "" && (
        <div className="uploadPreview">
          <img src={name} alt="File preview" />
          <div
            className={`waterMarkText 
            ${waterMark === "center-center" && "center"}
            ${waterMark === "top-center" && "top"}
            ${waterMark === "bottom-center" && "bottom"}
            ${waterMark === "no-watermark" && "hidden"}
            `}
          >
            Vízjel
          </div>
        </div>
      )}
      <div className="uploadWatermark">
        <select
          value={waterMark}
          onChange={(e) => {
            setWaterMark(e.target.value);
            localStorage.setItem("watermark", e.target.value);
          }}
        >
          <option value="no-watermark">Ne legyen vízjel!</option>
          {/*  <option value="center-center">Középen (teljesen)</option>
          <option value="top-center">Felül - Középen</option>
          <option value="bottom-center">Alul - Középen</option> */}
        </select>
      </div>

      {/*   <div className="opacityWatermark">
        <select
          className="centerSelect"
          value={opacity}
          onChange={(e) => {
            setOpacity(e.target.value);
            localStorage.setItem("opacityWatermark", e.target.value);
          }}
        >
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="60">60</option>
          <option value="iii-40"> Inverz !!! 40 </option>
          <option value="iii-50"> Inverz !!! 50 </option>
          <option value="iii-60"> Inverz !!! 60 </option>
        </select>
      </div> */}

      <form className="uploadForm">
        <input
          type="file"
          name="image"
          ref={ref}
          onChange={(event) => onChangeImg(event)}
        />

        <button type="button" onClick={() => startUploadAfterSave()}>
          Feltöltés
        </button>
      </form>
    </div>
  );
}
