export const StringToLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const StringFromLocalStorage = (key) => {
  const result = localStorage.getItem(key);
  if (result) {
    return result;
  } else {
    return "";
  }
};

export const ObjectToLocalStorage = (key, value) => {
  if (typeof object === "object") {
    const json = JSON.stringify(value);
    localStorage.setItem(key, json);
  } else {
    return false;
  }
};

export const ObjectFromLocalStorage = (key) => {
  const result = localStorage.getItem(key);
  if (result) {
    // van string
    const object = JSON.parse(result);
    return typeof object === "object" ? object : {};
  } else {
    return {};
  }
};
