import React from "react";
import { NavLink } from "react-router-dom";

export default function NavSectionItem({ sectionItem }) {
  return (
    <>
      {sectionItem.hr ? (
        <hr />
      ) : (
        <li>
          <NavLink to={sectionItem.link}>
            <img
              className="icon15"
              alt=""
              src={`${process.env.REACT_APP_SUBFOLDER}/template/${sectionItem.icon}`}
            />
            {sectionItem.title}
          </NavLink>
        </li>
      )}
    </>
  );
}
