import "./nav.css";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  StringFromLocalStorage,
  StringToLocalStorage,
} from "../__HKA/Function/LocalStorageHandler";

import NavSection from "./NavSection";

const createMenuObject = (string) => {
  if (string === "") {
    return {};
  }
  const menuBlockInfo = JSON.parse(string);
  if (typeof menuBlockInfo === "object") {
    return menuBlockInfo;
  }
  return {};
};

export default function Nav() {
  const [activeMenuBlock, setActiveMenuBlock] = useState(
    createMenuObject(StringFromLocalStorage("activeMenu"))
  );

  const createClassN = (string, uniqClass) => {
    const uniqClassName = uniqClass ? uniqClass : "";
    if (activeMenuBlock && activeMenuBlock[string]) {
      return `activeM ${uniqClassName}`;
    }
    return `notActiveM ${uniqClassName}`;
  };

  const toggL = (sectionTitle) => {
    const newMenuObj = { ...activeMenuBlock };
    if (newMenuObj[sectionTitle]) {
      newMenuObj[sectionTitle] = false;
    } else {
      newMenuObj[sectionTitle] = true;
    }
    StringToLocalStorage("activeMenu", JSON.stringify(newMenuObj));
    setActiveMenuBlock(newMenuObj);
  };

  const imgCrop = {
    title: "Képszerkesztő",
    alias: "kepvago",
    uniqClass: "mb-10",
    items: [
      {
        link: "/kepszerkeszto/facebook",
        icon: "img-editor.png",
        title: "Facebook",
      },
      {
        link: "/kepszerkeszto/negyzet",
        icon: "img-editor.png",
        title: "Négyzet",
      },
      {
        link: "/kepszerkeszto/haromketto",
        icon: "img-editor.png",
        title: "3:2 - fekvő",
      },
      {
        link: "/kepszerkeszto/kettoharom",
        icon: "img-editor.png",
        title: "2:3 - álló",
      },
      {
        link: "/kepszerkeszto/hatter",
        icon: "img-editor.png",
        title: "Nagy háttérkép",
      },
    ],
  };

  const blogNav = {
    title: "Blog",
    alias: "blog",
    uniqClass: "hupi",
    items: [
      {
        link: "/blog/uj",
        icon: "new_25.png",
        title: "Új blogposzt",
      },
      {
        link: "/blog",
        icon: "list_25.png",
        title: "Blogposztok",
      },
      {
        hr: true,
      },
      {
        link: "/blogkategoria/uj",
        icon: "new_25.png",
        title: "Új kategória",
      },
      {
        link: "/blogkategoria",
        icon: "list_25.png",
        title: "Kategóriák",
      },
    ],
  };
  const tartalomNav = {
    title: "Aloldal-tartalom",
    alias: "tartalom",
    items: [
      {
        link: "/tartalom/uj",
        icon: "new_25.png",
        title: "Új bejegyzés",
      },
      {
        link: "/tartalom",
        icon: "list_25.png",
        title: "Bejegyzések",
      },
      {
        hr: true,
      },
      {
        link: "/tartkategoria/uj",
        icon: "new_25.png",
        title: "Új kategória",
      },
      {
        link: "/tartkategoria",
        icon: "list_25.png",
        title: "Kategóriák",
      },
    ],
  };
  const contactNav = {
    title: "Elérhetőségek",
    alias: "kapcsolat",
    uniqClass: "",
    items: [
      {
        link: "/kapcsolat",
        icon: "list_25.png",
        title: "Kapcsolat",
      },
    ],
  };
  const fixNav = {
    title: "Központi adatok",
    alias: "global",
    uniqClass: "",
    items: [
      {
        link: "/fix",
        icon: "list_25.png",
        title: "Fix tartalmak",
      },
      {
        link: "/statisztikak",
        icon: "info.png",
        title: "Központi adatok",
      },
    ],
  };
  const gyikNav = {
    title: "GYIK",
    alias: "gyik",
    uniqClass: "",
    items: [
      {
        link: "/gyik",
        icon: "faq.png",
        title: "Bevezető aloldal",
      },
      {
        link: "/gyikkerdes/uj",
        icon: "new_25.png",
        title: "Új kérdés",
      },
      {
        link: "/gyikkerdes",
        icon: "list_25.png",
        title: "Kérdések",
      },
    ],
  };
  const menuNav = {
    title: "Menü",
    alias: "menu",
    uniqClass: "",
    items: [
      {
        link: "/menupont/uj",
        icon: "new_25.png",
        title: "Új menüpont",
      },
      {
        link: "/menupont",
        icon: "list_25.png",
        title: "Menüpontok",
      },
    ],
  };
  const modulNav = {
    title: "Modulok",
    alias: "modul",
    uniqClass: "",
    items: [
      {
        link: "/modul",
        icon: "list_25.png",
        title: "Modul-lista",
      },
    ],
  };
  const egyebNav = {
    title: "Egyéb",
    alias: "egyeb",
    uniqClass: "",
    items: [
      {
        link: "/atiranyitas",
        icon: "list_25.png",
        title: "Átirányítás",
      },
    ],
  };

  return (
    <div className="adminMenu">
      <div className="adminMenuInner">
        <NavSection secI={imgCrop} tg={toggL} cC={createClassN} />
{/*         <NavSection secI={blogNav} tg={toggL} cC={createClassN} />
        <NavSection secI={contactNav} tg={toggL} cC={createClassN} />
        <NavSection secI={gyikNav} tg={toggL} cC={createClassN} />
        <NavSection secI={tartalomNav} tg={toggL} cC={createClassN} />
        <NavSection secI={menuNav} tg={toggL} cC={createClassN} />
        <NavSection secI={fixNav} tg={toggL} cC={createClassN} />
        <NavSection secI={modulNav} tg={toggL} cC={createClassN} />
        <NavSection secI={egyebNav} tg={toggL} cC={createClassN} /> */}
      </div>
    </div>
  );
}
