import { Routes, Route } from "react-router-dom";

import ContentCategory from "./Content/Category/ContentCategory";
import ContentCategoryList from "./Content/Category/ContentCategoryList";
import ContentItem from "./Content/Item/ContentItem";
import ContentItemList from "./Content/Item/ContentItemList";

import MenuItemList from "./Menu/MenuItemList";
import MenuItem from "./Menu/MenuItem";

import ArticleList from "./Article/ArticleList";
import Article from "./Article/Article";

import Contact from "./Contact/Contact";
import ContactPageList from "./Contact/ContactPageList";

import RealBlogCategory from "./RealBlog/Category/RealBlogCategory";
import RealBlogCategoryList from "./RealBlog/Category/RealBlogCategoryList";
import RealBlogItemList from "./RealBlog/Item/RealBlogItemList";
import RealBlogItem from "./RealBlog/Item/RealBlogItem";

import Module from "./Module/Module";
import ModuleList from "./Module/ModuleList";

import Stats from "./Stats/Stats";
import NotFound from "./NotFound/NotFound";
import NotFoundItem from "./NotFound/NotFoundItem";

import ImgCropFacebook from "./ImgCrop/ImgCropFacebook";
import ImgCropNegyzet from "./ImgCrop/ImgCropNegyzet";
import ImgCropBackground from "./ImgCrop/ImgCropBackground";
import ImgCrop32 from "./ImgCrop/ImgCrop32";
import ImgCrop23 from "./ImgCrop/ImgCrop23";

import GyikCategory from "./Gyik/Category/GyikCategory";
import GyikItemList from "./Gyik/Item/GyikItemList";
import GyikItem from "./Gyik/Item/GyikItem";

export default function MainAdmin({ user, logTheUserOut }) {
  return (
    <div className="fullMain">
      {user && (
        <Routes>
          <Route path="kepszerkeszto/facebook" element={<ImgCropFacebook />} />
          <Route path="kepszerkeszto/negyzet" element={<ImgCropNegyzet />} />
          <Route path="kepszerkeszto/hatter" element={<ImgCropBackground />} />
          <Route path="kepszerkeszto/haromketto" element={<ImgCrop32 />} />
          <Route path="kepszerkeszto/kettoharom" element={<ImgCrop23 />} />
{/* 
          <Route path="tartalom" element={<ContentItemList />} />
          <Route path="tartalom/:id" element={<ContentItem />} />
          <Route path="tartkategoria" element={<ContentCategoryList />} />
          <Route path="tartkategoria/:id" element={<ContentCategory />} />

          <Route path="blog" element={<RealBlogItemList />} />
          <Route path="blog/:id" element={<RealBlogItem />} />
          <Route path="blogkategoria/:id" element={<RealBlogCategory />} />
          <Route path="blogkategoria" element={<RealBlogCategoryList />} />

          <Route path="gyik" element={<GyikCategory />} />
          <Route path="gyikkerdes" element={<GyikItemList />} />
          <Route path="gyikkerdes/:id" element={<GyikItem />} />

          <Route path="kapcsolat/:id" element={<Contact />} />
          <Route path="kapcsolat" element={<ContactPageList />} />

          <Route path="menupont/:id" element={<MenuItem />} />
          <Route path="menupont" element={<MenuItemList />} />

          <Route path="modul/:id" element={<Module />} />
          <Route path="modul" element={<ModuleList />} />

          <Route path="fix/:id" element={<Article />} />
          <Route path="fix" element={<ArticleList />} />

          <Route path="statisztikak" element={<Stats />} />
          <Route path="atiranyitas" element={<NotFound />} />
          <Route path="atiranyitas/:id" element={<NotFoundItem />} /> */}
        </Routes>
      )}
    </div>
  );
}
