import "./loadingMask.css";

export default function LoadingMask() {
  return (
    <div className="loadingMask">
      <div id="loaderDiv">
        <div className="loaderBox">
          <div className="loader"></div>
        </div>
      </div>
    </div>
  );
}
