import React from "react";
import NavSectionItem from "./NavSectionItem";

export default function NavSection({ secI, tg, cC }) {
  return (
    <>
      <section
        className={`menuSection ${cC(secI.alias, secI.uniqClass)}`}
      >
        <h3 onClick={() => tg(secI.alias)}>{secI.title}</h3>
        <div className="secBlock">
          {secI.items.length > 0 && (
            <ul>
              {secI.items.map((item, i) => (
                <NavSectionItem key={i} sectionItem={item} />
              ))}
            </ul>
          )}
        </div>
      </section>
    </>
  );
}
