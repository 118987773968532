import "./article.css";
import { useState, useEffect } from "react";

import createAlias from "../__Functions/AliasCreator";
import TinyEditor from "../Editor/TinyEditor";

import axios from "axios";
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

export default function ArticleValues({ article, getArticle }) {
  const [articleID, setArticleID] = useState(false);
  //const [article, setArticle] = useState(false);

  const [available, setAvailable] = useState(true);

  const [title, setTitle] = useState("");
  const [alias, setAlias] = useState("");
  const [s_desc, setS_desc] = useState("");
  const [full_desc, setFull_desc] = useState("");

  const [brows_title, setBrows_title] = useState("");
  const [g_keys, setG_keys] = useState("");
  const [g_fb_desc, setG_FB_desc] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {}, []);

  // useEffect(() => {}, [available]);

  useEffect(() => {
    if (article.id) {
      setArticleID(article.id);

      setTitle(article.title);
      setAlias(article.alias);
      setS_desc(article.s_desc);
      setFull_desc(article.full_desc);

      setBrows_title(article.brows_title);
      setG_FB_desc(article.g_fb_desc);
      setG_keys(article.g_keys);
    }
  }, [article]);

  const updateConfig = () => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  };

  const createUpdateArticle = () => {
    updateConfig();
    const payload = {
      id: articleID,
      title,
      alias,
      s_desc,
      full_desc,
      brows_title,
      g_fb_desc,
      g_keys,
    };

    console.log(`payload --- `, payload);

    axios
      .put(
        `${process.env.REACT_APP_HTTP_HOST}/api/auth/article/article${process.env.REACT_APP_PHP}`,
        payload,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          window.location.href = `${process.env.REACT_APP_SUBFOLDER}/fix/${res.data.article.id}`;
        }
      })
      .catch((err) =>
        err.response
          ? console.log(err.response.status, err.response.data)
          : console.error(err)
      )
      .finally();
  };

  const setPublished = async (id, nextStatus) => {
    updateConfig();
    setLoading(true);
    const payload = {
      id,
      nextStatus,
    };
    axios
      .patch(
        `${process.env.REACT_APP_HTTP_HOST}/api/auth/article/article${process.env.REACT_APP_PHP}`,
        payload,
        config
      )
      .then((res) => {
        console.log(res.data);
        getArticle();
      })
      .catch((err) =>
        err.response
          ? console.log(err.response.status, err.response.data)
          : console.error(err)
      )
      .finally(setLoading(false));
  };

  const copyTitle = () => {
    setBrows_title(title);
  };

  return (
    <div className="articleValues">
      <div className="blogInputRow">
        {article.id && (
          <div
            className={`publishedInfo ${
              article.published === "1" ? "default" : "unpublished"
            }`}
          >
            {article.no_del !== "1" && (
              <div>
                {article.published === "1" ? (
                  <div>
                    <img
                      onClick={() => setPublished(article.id, 0)}
                      className="icon25 clickable"
                      src="/template/ok.png"
                    />
                    Ez a hír/cikk elérhető a honlapon.
                  </div>
                ) : (
                  <div>
                    <img
                      onClick={() => setPublished(article.id, 1)}
                      className="icon25 clickable"
                      src="/template/minus.png"
                    />
                    Ez az bejegyzés REJTETT, ezért nem jelenik meg a honlapon
                    most !!
                  </div>
                )}
              </div>
            )}
          </div>
        )}{" "}
        <div className="title">
          <div>Cím, név</div>
          <input
            type="text"
            value={title}
            placeholder="Cím / Név"
            onChange={(e) => {
              setTitle(e.target.value);
              setBrows_title(e.target.value);
              setAlias(createAlias(e.target.value));
            }}
          />
        </div>
        <div className={`alias`}>
          <div>Alias</div>
          <input
            className={alias === "" || available ? "default" : "errorInput"}
            type="text"
            value={alias}
            disabled
            placeholder="Alias"
            onChange={(e) => {
              setAlias(e.target.value);
            }}
          />
        </div>
        <div className="sDesc">
          <div>Rövid leírás</div>
          <textarea
            placeholder="Rövid leírás"
            value={s_desc}
            onChange={(e) => {
              setS_desc(e.target.value);
            }}
          ></textarea>
        </div>
        <div className="rowInput">
          <div>Hosszú leírás</div>
          <TinyEditor
            data={article.full_desc}
            sendToStateFunction={setFull_desc}
          />
        </div>
        <div className="seoBlock">
          <div className="gTitle">
            <div>
              Böngészőben a főcím (Google találat és FB megosztás)
              <span className="seoSpanBtn" onClick={copyTitle}>
                Cím átmásolása
              </span>
            </div>
            <input
              type="text"
              value={brows_title}
              onChange={(e) => {
                setBrows_title(e.target.value);
              }}
            />
          </div>
          <div className="gFbDesc">
            <div>Google-nek és a FB-nak leírás</div>
            <textarea
              value={g_fb_desc}
              onChange={(e) => {
                setG_FB_desc(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="gKeys">
            <div>Google kulcsszavak</div>
            <input
              type="text"
              value={g_keys}
              onChange={(e) => {
                setG_keys(e.target.value);
              }}
            />
          </div>
        </div>
        {alias === "" ? (
          <div className="errors">Nincs Cím / Alias beállítva!</div>
        ) : (
          <button className="saveBtn" onClick={() => createUpdateArticle()}>
            Mentés
          </button>
        )}
      </div>
    </div>
  );
}
