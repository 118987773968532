import "./article.css";
import { useState, useEffect } from "react";

import ArticleCategoryView from "./ArticleCategoryView";
import ArticleCategoryViewNoDel from "./ArticleCategoryViewNoDel";

import axios from "axios";
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

export default function ArticleList({ user, logTheUserOut }) {
  const [articles, setBlogitems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getArticles();
  }, []);

  useEffect(() => {}, [loading]);

  const updateConfig = () => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  };

  const getArticles = async (searchObject = {}) => {
    updateConfig();
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_HTTP_HOST}/api/auth/article/article-list${process.env.REACT_APP_PHP}`,
        searchObject,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.user) {
          logTheUserOut();
        }
        setBlogitems(res.data.articles ? res.data.articles : []);
      })
      .catch((err) =>
        err.response
          ? console.log(err.response.status, err.response.data)
          : console.error(err)
      )
      .finally(setLoading(false));
  };

  const setPublished = async (id, nextStatus) => {
    updateConfig();
    setLoading(true);
    const payload = {
      id,
      nextStatus,
    };
    axios
      .patch(
        `${process.env.REACT_APP_HTTP_HOST}/api/auth/article/article${process.env.REACT_APP_PHP}`,
        payload,
        config
      )
      .then((res) => {
        console.log(res.data);
        getArticles();
      })
      .catch((err) =>
        err.response
          ? console.log(err.response.status, err.response.data)
          : console.error(err)
      )
      .finally(setLoading(false));
  };

  return (
    <div className="articleList">
      <h1>Cikkek</h1>
      {articles.length > 0 ? (
        articles.map((article) => {
          if (article.no_del === "1") {
            return (
              <ArticleCategoryViewNoDel key={article.id} article={article} />
            );
          } else {
            return (
              <ArticleCategoryView
                key={article.id}
                article={article}
                setPublished={setPublished}
              />
            );
          }
        })
      ) : (
        <div className="infoBlock">
          <div className="normal">Nincs találat.</div>
        </div>
      )}
    </div>
  );
}
