import "./menu.css";
import { useState, useEffect } from "react";

import AliasCreator from "../__Functions/AliasCreator";
import SelectableItem from "./SelectableItem";
import AllFetchMethod from "../__Functions/Fetch/AllFetchMethod";
import ReOrderByLvl from "../__Functions/ReOrderByLvl";
import CreateSpace from "../__HKA/Function/CreateSpace";

import axios from "axios";
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

export default function MenuItemValues({ menuItem, getMenuItem }) {
  const [id, setId] = useState(false);
  const [title, setTitle] = useState("");

  const [alias, setAlias] = useState("");
  const [aliasAvailable, setAliasAvailable] = useState(true);

  const [menu_id, setMenu_id] = useState(0);
  const [parent_id, setParent_id] = useState(0);
  const [menuItemtype, setMenuItemtype] = useState(0);
  const [maxSubDepth, setMaxSubDepth] = useState(0);
  const [controllerName, setControllerName] = useState(0);
  const [id_of_type, setId_of_type] = useState(0);

  const [unique_class, setUnique_class] = useState("");

  // const [published, setPublished] = useState(0);

  const [selected, setSelected] = useState({});
  const [showSelectable, setShowSelectable] = useState(false);

  const [types, setTypes] = useState([]);
  const [menuBlocks, setMenuBlocks] = useState([]);

  const [menuItems, setMenuItems] = useState([]);
  const [menuItemsByMenu, setMenuItemsByMenu] = useState({});
  const [relatedMenuItems, setRelatedMenuItems] = useState([]);

  const [selectableList, setSelectableList] = useState([]);

  const [loading, setLoading] = useState(false);

  const [allArrived, setAllArrived] = useState(false);

  useEffect(() => {
    if (allArrived && menuItem.id) {
      setId(menuItem.id);
      setTitle(menuItem.title);
      setAlias(menuItem.alias);
      setMenu_id(menuItem.menu_id);
      setParent_id(menuItem.parent_id);
      setMenuItemtype(menuItem.type);
      setMaxSubDepth(menuItem.max_sub_depth);
      setControllerName(menuItem.controller_name);
      setId_of_type(menuItem.id_of_type);
      setUnique_class(menuItem.unique_class);

      if (menuItem.selected) {
        console.log("selected:", menuItem.selected);
        setSelected({
          id: menuItem.selected.id,
          title: menuItem.selected.title,
        });
      } else {
        console.log("NINCS selected.....");
      }
    }
    getLists();
  }, [allArrived]);

  useEffect(() => {
    if (Number(menuItemtype) > 0) {
      console.log("változott a menuItemType");
      getMenuItemSelector();
    }
  }, [menuItemtype]);

  useEffect(() => {
    findAlias();
  }, [alias]);

  useEffect(() => {
    console.log("lefut a menupont állító", menu_id);
    // ha a menu_id válozik és nem 0
    if (Number(menu_id) !== 0) {
      console.log("új menüpontok kellenek");
      console.log(menuItemsByMenu);
      setMenuItems(ReOrderByLvl(menuItemsByMenu[menu_id]));
    } else {
      console.log("lista legyen üres");
      setMenuItems([]);
    }
  }, [menu_id]);

  const updateConfig = () => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  };

  const createUpdateMenuitem = async () => {
    const payload = {
      id,
      title,
      alias,
      menu_id,
      type: menuItemtype,
      max_sub_depth: maxSubDepth,
      controller_name: controllerName,
      id_of_type,
      parent_id,
      unique_class,
      //  published,
    };
    // console.log(payload);

    // return;
    updateConfig();
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_HTTP_HOST}/api/auth/menu/menuitem${process.env.REACT_APP_PHP}`,
        payload,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.menuItem) {
          window.location.href = `${process.env.REACT_APP_SUBFOLDER}/menupont/${res.data.menuItem.id}`;
        }
      })
      .catch((err) =>
        err.response
          ? console.log(err.response.status, err.response.data)
          : console.error(err)
      )
      .finally(setLoading(false));
  };

  const getLists = async () => {
    const result = await AllFetchMethod("get", "/api/auth/menu/menuitem-list");
    console.log("getlists ", result);
    if (result.success) {
      setTypes(result.types ? result.types : []);
      console.log("result.menuBlocks", result.menuBlocks);
      setMenuBlocks(result.menuBlocks ? result.menuBlocks : []);
      setMenuItemsByMenu(result.menuItemsByMenu ? result.menuItemsByMenu : {});
      setAllArrived(true);
    }
  };

  const findAlias = () => {
    updateConfig();
    setLoading(true);
    const payload = {
      id,
      alias,
      findby: "alias",
    };
    axios
      .post(
        `${process.env.REACT_APP_HTTP_HOST}/api/auth/menu/menuitem-findby${process.env.REACT_APP_PHP}`,
        payload,
        config
      )
      .then((res) => {
        setAliasAvailable(res.data.available);
      })
      .catch((err) =>
        err.response
          ? console.log(err.response.status, err.response.data)
          : console.error(err)
      )
      .finally(setLoading(false));
  };

  const getMenuItemSelector = async () => {
    const result = await AllFetchMethod("post", "/api/auth/menu/selectable", {
      type: menuItemtype,
    });
    if (result.success) {
      setSelectableList(result.items ? result.items : []);
    }
  };

  const selectThis = (selectedID, selectedTitle) => {
    setId_of_type(selectedID);
    const item = {
      id: selectedID,
      title: selectedTitle,
    };
    setSelected(item);
    setShowSelectable(false);
  };

  const setPublished = async (id, nextStatus) => {
    const result = await AllFetchMethod("patch", "/api/auth/menu/menuitem", {
      id,
      nextStatus,
    });
    if (result.success) {
      getMenuItem();
    }
  };

  const setMenuItemParams = (itemData) => {
    //  console.log("itemData ", itemData);
    for (const typeInfo of types) {
      if (typeInfo.id === Number(itemData)) {
        // console.log(typeInfo)
        setMenuItemtype(typeInfo.id);
        setMaxSubDepth(typeInfo.info.maxSubDepth);
        setControllerName(typeInfo.info.controller_name);
      }
    }
  };

  return (
    <div className="createUpdateMenuitem">
      {menuItem.id && (
        <div
          className={`publishedInfo ${
            menuItem.published === "1" ? "default" : "unpublished"
          }`}
        >
          {menuItem.published === "1" ? (
            <div>
              <img
                onClick={() => setPublished(menuItem.id, 0)}
                className="icon25 clickable"
                src={`${process.env.REACT_APP_SUBFOLDER}/template/ok.png`}
              />
              Ez a tartalom elérhető a honlapon.
            </div>
          ) : (
            <div>
              <img
                onClick={() => setPublished(menuItem.id, 1)}
                className="icon25 clickable"
                src={`${process.env.REACT_APP_SUBFOLDER}/template/minus.png`}
              />
              Ez az tartalom REJTETT, ezért nem jelenik meg a honlapon most !!
            </div>
          )}
        </div>
      )}

      <div className="inputHorizontal">
        <div className="rowInput">
          <div>Cím</div>
          <input
            className={title === "" ? "solidError" : "greenOK"}
            type="text"
            value={title}
            placeholder="név.."
            onChange={(e) => {
              setTitle(e.target.value);
              setAlias(AliasCreator(e.target.value));
            }}
          />
        </div>
        <div className="rowInput">
          <div>Alias</div>
          <input
            type="text"
            value={alias}
            className={aliasAvailable ? "available" : "notAvailable"}
            disabled
            placeholder="alias.."
            onChange={(e) => {
              setAlias(e.target.value);
            }}
          />
        </div>
        {/*         <div className="rowInput">
          <div>Egyedi stílus neve (ha kell)</div>
          <input
            type="text"
            value={unique_class}
            placeholder="alias.."
            onChange={(e) => {
              setUnique_class(e.target.value);
            }}
          />
        </div> */}
      </div>

      <div className="inputHorizontal">
        <div className="rowInput">
          <div>Menüblokk</div>
          <select
            className={Number(menu_id) === 0 ? "solidError" : "greenOK"}
            value={menu_id}
            onChange={(e) => setMenu_id(e.target.value)}
          >
            <option value="0">-- nincs --</option>
            {menuBlocks.map((menuBlock) => {
              if (menuBlock.id === id) {
                return (
                  <option disabled key={menuBlock.id} value={menuBlock.id}>
                    {menuBlock.title}
                  </option>
                );
              } else {
                return (
                  <option key={menuBlock.id} value={menuBlock.id}>
                    {menuBlock.title}
                  </option>
                );
              }
            })}
          </select>
        </div>
      </div>

      <div className="inputHorizontal">
        <div className="rowInput">
          <div>Szülőmenüpont, ha van</div>

          {Number(menu_id) === 0 ? (
            "Előbb menüblokkot kell választani!"
          ) : (
            <div>
              {menuItems && (
                <select
                  value={parent_id}
                  onChange={(e) => setParent_id(e.target.value)}
                >
                  <option value="0">-- nincs --</option>
                  {menuItems &&
                    menuItems.map((menItem) => {
                      if (menItem.id === id) {
                        return (
                          <option disabled key={menItem.id} value={menItem.id}>
                            {CreateSpace(menItem.lvl)}
                            {menItem.title}
                          </option>
                        );
                      } else {
                        return (
                          <option key={menItem.id} value={menItem.id}>
                            {CreateSpace(menItem.lvl)}
                            {menItem.title}
                          </option>
                        );
                      }
                    })}
                </select>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="inputHorizontal">
        <div className="rowInput">
          <div>A tartalom típusa</div>
          <select
            className={Number(menuItemtype) === 0 ? "solidError" : "greenOK"}
            value={menuItemtype}
            placeholder="..."
            onChange={(e) => setMenuItemParams(e.target.value)}
          >
            {types.map((type) => {
              return (
                <option key={type.id} value={type.id}>
                  {type.info.title}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="inputHorizontal">
        <div className="selectMenuItemBox">
          <div
            className={`selectedItem ${
              !selected.id ? "solidError" : "greenOK"
            }`}
          >
            <div>A választott elem:</div>
            {selected.id ? <div>{selected.title}</div> : <div>-----</div>}
          </div>
          {!showSelectable && (
            <button onClick={() => setShowSelectable(true)}>Kiválasztás</button>
          )}
        </div>
      </div>
      {showSelectable && (
        <div className="selectableBox">
          <div>Válassz egy elemet: </div>
          <div>
            {selectableList.length > 0 ? (
              selectableList.map((item) => {
                return (
                  <SelectableItem
                    key={item.id}
                    item={item}
                    selectThis={selectThis}
                  />
                );
              })
            ) : (
              <div>Nem érhető el egy elem sem.</div>
            )}
          </div>
        </div>
      )}

      <div className="saveBtnDiv">
        {aliasAvailable ? (
          <button className="saveBtn" onClick={() => createUpdateMenuitem()}>
            Mentés
          </button>
        ) : (
          <div className="error">Nem menthető, mert az alias foglalt</div>
        )}
      </div>
    </div>
  );
}
