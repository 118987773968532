import "./cropper.css";

import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const doAction = () => {
  const canvas = document.getElementById("preCanvas");

  canvas.toBlob(
    (blob) => {
      const anchor = document.createElement("a");
      anchor.download = (2000000000000 - Date.now()).toString() + ".jpg"; // optional, but you can give the file a name
      anchor.href = URL.createObjectURL(blob);

      anchor.click(); // ✨ magic!

      URL.revokeObjectURL(anchor.href); // remove it from memory and save on memory! 😎

      /*       const newImg = document.createElement("img");
      const url = URL.createObjectURL(blob);

      newImg.onload = () => {
        // no longer need to read the blob so it's revoked
        URL.revokeObjectURL(url);
      };

      newImg.src = url;
      document.body.appendChild(newImg); */
    },
    "image/jpeg",
    0.99
  );
};

const writeSizeInfo = (finalWidth = 0, finalHeight = 0) => {
  console.log("writeSizeInfo", finalWidth, finalHeight);

  if (finalWidth * finalHeight === 0) {
    return `<div class="solidError">Hibás méretek</div>`;
  } else if (
    finalWidth / finalHeight > (1.01 * 2) / 3 ||
    finalWidth / finalHeight < (0.99 * 2) / 3
  ) {
    return `<div class="solidError">Ez nem FB képarány!</div>`;
  }

  if (finalWidth < 500) {
    return `<div class="infoMsg solRed">Ez a kép túl kicsi lesz</div>`;
  } else if (finalWidth < 700) {
    return `<div class="infoMsg solBlue">Elfogadható, de kicsit nagyobb kellene.</div>`;
  } else {
    return `<div class="infoMsg solGreen">Ez a kép rendben lesz.</div>`;
  }
};

export default function ImgCrop23() {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [widthCorrection, setWidthCorrection] = useState(0);
  const [heightCorrection, setHeightCorrection] = useState(0);
  const [brightness, setBrightness] = useState(0);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(2 / 3);
  const [finalWidth, setFinalWidth] = useState(0);
  const [finalHeight, setFinalHeight] = useState(0);

  let params = useParams();
  useEffect(() => {
    /*     console.log(params.label);
    setImgSrc("");
    if (params.label === "facebook") {
      setAspect(120 / 63);
    } else {
      setAspect(1);
    } */
  }, [params]);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      //  console.log("onselectfile");
    }
    // torzításokat kiszedni!
    setScale(1);
    setRotate(0);
    setWidthCorrection(0);
    setHeightCorrection(0);
    setBrightness(0);
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
    console.log("onimageload");
  }

  useDebounceEffect(
    async () => {
      if (!completedCrop) {
      } else {
        if (
          true ||
          (completedCrop.width &&
            completedCrop.height &&
            imgRef.current &&
            previewCanvasRef.current)
        ) {
          // We use canvasPreview as it's much faster than imgPreview.
          canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop,
            scale,
            rotate,
            widthCorrection,
            heightCorrection,
            brightness
          );
        }
        console.log("use effectt");
        getCanvasDimensions();
      }
    },
    100,
    [
      completedCrop,
      scale,
      rotate,
      widthCorrection,
      heightCorrection,
      brightness,
    ]
  );

  /*   function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(16 / 9);
      setCrop(centerAspectCrop(width, height, 120 / 63));
    }
  } */

  const getCanvasDimensions = () => {
    console.log("const getCanvasDimensions");
    const canvas = document.getElementById("preCanvas");
    setFinalWidth(canvas ? canvas.width : 0);
    setFinalHeight(canvas ? canvas.height : 0);
  };

  return (
    <div className="cropperArea">
      <div className="cropControls">
        <div>
          <div className="uploadForm cropUpload">
            <input type="file" accept="image/*" onChange={onSelectFile} />
          </div>
          <div className="cropGrid">
            <div className="cropInpDiv">
              <label htmlFor="scale-input">Nagyítás</label>
              <input
                id="scaleI"
                type="number"
                step="0.1"
                value={scale}
                disabled={!imgSrc}
                onChange={(e) => setScale(Number(e.target.value))}
              />
            </div>
            <div className="cropInpDiv">
              <label htmlFor="rotate-input">Forgatás</label>
              <input
                id="rotateI"
                type="number"
                value={rotate}
                disabled={!imgSrc}
                onChange={(e) =>
                  setRotate(
                    Math.min(180, Math.max(-180, Number(e.target.value)))
                  )
                }
              />
            </div>
            <div className="cropInpDiv">
              <label htmlFor="scale-input">Vízszintes T.</label>
              <input
                id="horT"
                type="number"
                step="1"
                value={widthCorrection}
                disabled={!imgSrc}
                onChange={(e) => setWidthCorrection(Number(e.target.value))}
              />
            </div>
            <div className="cropInpDiv">
              <label htmlFor="scale-input">Függőleges T.</label>
              <input
                id="verT"
                type="number"
                step="1"
                value={heightCorrection}
                disabled={!imgSrc}
                onChange={(e) => setHeightCorrection(Number(e.target.value))}
              />
            </div>
            <div className="cropInpDiv">
              <label htmlFor="scale-input">Fényerő (+/-)</label>
              <input
                id="brightNess"
                type="number"
                step="1"
                value={brightness}
                disabled={!imgSrc}
                onChange={(e) => setBrightness(Number(e.target.value))}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ddCols">
        <div>
          {!!completedCrop && (
            <div>
              <canvas
                id="preCanvas"
                ref={previewCanvasRef}
                style={{
                  border: "0px solid black",
                  objectFit: "contain",
                  width: "100%",
                  height: "auto",
                }}
              />
              <div className="sizeInfo">
                <div>
                  {finalWidth} * {finalHeight} px
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: writeSizeInfo(finalWidth, finalHeight),
                  }}
                />
              </div>
              <div>
                <button onClick={() => doAction()}>Kép mentése</button>
              </div>
            </div>
          )}
        </div>

        <div>
          {console.log("assp ", aspect)}
          {imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </div>
      </div>
    </div>
  );
}
